import React, {useEffect, useState} from "react";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import getFirebase from 'src/utilities/firebase'
import firebase from 'firebase/app'
import AnalyticsHelmet from 'src/components/AnalyticsHelmet'
import { identify, trackPage, trackEvent} from 'src/utilities/analytics'
import 'firebase/auth';
import Modal from 'src/components/Modal';
import XCircle from 'src/images/icons/x-circle.svg'
import 'src/styles/firebaseui-styling.global.css'

const pageName = 'Login Modal'

const defaultSignInSuccessWithAuthResult = () => {
  trackEvent('sign_in_success', {
    page: pageName,
  })
  return null
}

const SkipLoginContainer = ({onClickContinueAsGuest}) => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexGrow: 1,
    alignItems: 'center',
  }
  const lineContainerStyle = {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: '',
    marginTop: 40,
    marginBottom: 40,
  }
  const sharedLineStyle = {
    height: 1,
    flexGrow: 1,
    width: '40%',
    backgroundColor: 'rgb(228, 228, 228)'
  }
  const leftLineStyle = {
    ...sharedLineStyle,
    marginRight: 20,
  }
  const rightLineStyle = {
    ...sharedLineStyle,
    marginLeft: 20,
  }

  const buttonStyle = {
    width: 240,
  }
  const smallStyle = {
    fontSize: 12,
  }
  return (
    <div style={containerStyle}>
      <div style={lineContainerStyle}>
        <div style={leftLineStyle}/>
          <small style={smallStyle}>
            or
          </small>
        <div style={rightLineStyle}/>
      </div>

      <button
        className='btn btn-outline-secondary' style={buttonStyle}
        onClick={onClickContinueAsGuest}
      >
        Continue as Guest
      </button>
    </div>
    )
}

 // Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // not supporting signInSuccessURL since callbacks.signInSuccessWithAuthResult
  // can provide needed functionality and can consolidate analytics calls through there
  // signInSuccessUrl: '/resumes/',
  // default behavior after sign in is NO-OP
  callbacks: {
    signInSuccessWithAuthResult: defaultSignInSuccessWithAuthResult,
  },
  // We will display Google and Facebook as auth providers.
  signInOptions: [
  // use firebase lib, not instance
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID, // email can be set up to use no-password-email-links
    // requires more set up in dev console
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
  ]
}

// override sign in success redirect with signInSuccessWithAuthResult closure
const LoginModal = ({firebase, isOpen, onRequestClose, signInSuccessWithAuthResult, shouldAllowSkipLogin}) => {
  const [currentUser, setCurrentUser] = useState(null)
  useEffect(() => {
    if (!firebase) return;
    return firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user)
      identify(user)
    });
    }, [firebase]);

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
  const footerStyle = {
    paddingTop: 20,
  }

  let authUIConfig = {...uiConfig}
  if (signInSuccessWithAuthResult) {
    authUIConfig.callbacks = {
      signInSuccessWithAuthResult: (authResult) => {
        defaultSignInSuccessWithAuthResult()
        signInSuccessWithAuthResult(authResult)
    }}
    authUIConfig.signInSuccessUrl = null
  }
  const onAfterOpen = () => {
    trackPage(pageName)
  }
  const onAfterClose = () => {
    trackEvent('login_modal_closed', {
      page: pageName,
    })
  }
  const onClickContinueAsGuest = () => {
    if (!firebase) return
    if (!currentUser) {
      firebase.auth().signInAnonymously()
      .then(userCredential => {
        // returns anonymous currentUser
        return userCredential.user
      })
      .then(currentUser => {
        trackEvent('sign_in_success', {
          page: pageName,
          isAnonymous: true,
        })
      })
      .catch(error => console.error('signInAnonymously error', error))
      // maybe chain in sign in success with auth result. maybe not.
      // not clear if that is different arg or not
      // hold off for now
    }
    trackEvent('clicked_continue_as_guest', {page: pageName})
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={pageName}
      {...{onAfterOpen, onAfterClose}}
    >
      <AnalyticsHelmet/>
      <div style={containerStyle}>
        <h2 className="text-primary font-weight-bolder align-center"> Sign in to NextLevelResume.co</h2>
        <h3 className="text-secondary">Save your progress, update resumes, manage your account, and more.</h3>
        {
          firebase &&
          <StyledFirebaseAuth uiConfig={authUIConfig} firebaseAuth={firebase.auth()}/>
        }
        <h4 style={footerStyle} className="text-dark">Don't have an account? Sign in to create an account automatically.</h4>
        {
          shouldAllowSkipLogin &&
          <SkipLoginContainer {...{onClickContinueAsGuest}}/>
        }
      </div>
    </Modal>
  )
}

export default LoginModal
