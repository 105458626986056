import React, {useState, useEffect} from "react"
import {Link} from 'gatsby'
import PropTypes from "prop-types"
import Header from "./header"
import LoginModal from 'src/components/LoginModal'
import useFirebase from 'src/components/useFirebase'

const Footer = ({currentUser}) => {
  const companyName = 'NextLevelResume.co'
  const createResumeDestinationURL = currentUser ? '/resumes' : '/resume-templates'
  return (
    <footer className="py-8 py-md-11 bg-dark">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-4">

            <div className="footer-brand mb-2 h3">
              {companyName}
            </div>

            <p className="text-gray-600 mb-8">
              A beautiful resume in 5 minutes.
            </p>

          </div>

          <div className="col-12 col-md-4 col-lg-2">

            <div className="font-weight-bold text-uppercase text-gray-600 h6">
              Products
            </div>

            <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
              <li className="mb-3">
                <Link to={createResumeDestinationURL} className='text-reset'>
                  Create a Resume
                </Link>
              </li>
              <li className="mb-3">
                <Link to='/resume-templates' className='text-reset'>
                  Templates
                </Link>
              </li>
            </ul>

          </div>
          <div className="col-12 col-md-4 col-lg-2">

            <div className="font-weight-bold text-uppercase text-gray-600 h6">
              Manage
            </div>

            <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
              <li className="mb-3">
                <Link to='/resumes' className='text-reset'>
                  My Resumes
                </Link>
              </li>
              <li className="mb-3">
                <Link to='/account' className='text-reset'>
                  Account
                </Link>
              </li>
              <li className="mb-3">
                <Link to='/plans' className='text-reset'>
                  Plans
                </Link>
              </li>
            </ul>

          </div>
          <div className="col-12 col-md-4 offset-md-4 col-lg-2 offset-lg-0">

            <div className="font-weight-bold text-uppercase text-gray-600 h6">
              Legal
            </div>

            <ul className="list-unstyled text-muted mb-0">
              <li className="mb-3">
                <Link to='/terms-of-service' className='text-reset'>
                  Terms of Service
                </Link>
              </li>
              <li className="mb-3">
                <Link to='/privacy-policy' className='text-reset'>
                  Privacy Policy
                </Link>
              </li>
            </ul>

          </div>

        </div>
      </div>
    </footer>
    )
}
// shouldForceLoginModalOpenIfNoCurrentUser set to true
// prevents login modal from being dismissed if there is no current user
const Layout = ({ children, shouldForceLoginModalOpenIfNoCurrentUser, onSignInSuccess, onLogout, className,}) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false)
  const firebase = useFirebase();

  useEffect(() => {
    if (!firebase) return;
    return firebase.auth().onAuthStateChanged((user) => {
     setCurrentUser(user)
     forceLoginModalOpenIfNoCurrentUser({user})
    });
  }, [firebase]);

  const forceLoginModalOpenIfNoCurrentUser = ({user}) => {
    if (shouldForceLoginModalOpenIfNoCurrentUser && !user) {
      setLoginModalIsOpen(true)
    }
  }

  const onLoginPress = () => {
    setLoginModalIsOpen(true)
  }
  const onRequestClose = () => {
    setLoginModalIsOpen(false)
    forceLoginModalOpenIfNoCurrentUser({user: currentUser})
  }
  const signInSuccessWithAuthResult = (authResult) => {
    if (onSignInSuccess) {
      onSignInSuccess(authResult)
    }
    setLoginModalIsOpen(false)
  }

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh', // doing this to get footer on bottom of page
    // even when content doesn't go all the way down
    // i was doing things like here
    // https://dev.to/hzburki/100-height-to-all-divs-gatsby-33nd
    // setting html, body, and some gatsby element heights to 100%
    // but those produced unwanted side effects that were hard to contain
    // since they were css affecting more general elements
  }
  const mainStyle = {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  }

  const classes = `bg-light ${className}`
  return (
    <div className={classes} style={containerStyle}>
      {
        firebase &&
        <LoginModal isOpen={loginModalIsOpen} {...{firebase, onRequestClose, signInSuccessWithAuthResult,}}/>
      }
      <Header siteTitle='NextLevelResume.co' {...{firebase, currentUser, onLoginPress, onLogout}}/>
      <main style={mainStyle}>{children}</main>
      <Footer {...{currentUser}}/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
