import React, {useEffect, useState} from "react";
import ReactModal from 'react-modal';
import XCircle from 'src/images/icons/x-circle.svg'

// https://github.com/reactjs/react-modal/blob/master/docs/styles/index.md
const customStyles = {
  overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      zIndex: 1050,
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    borderRadius: '2%',
    padding: 50,

    // on mobile, keep modal inside screen, and allow scroll
    // https://github.com/reactjs/react-modal/issues/283
    maxWidth: 'calc(100vw - 2rem)',
    maxHeight: 'calc(100vh - 2rem)',
    boxShadow: '0 0 30px 0 rgba(0, 0, 0, 0.25)',
    overflowY: 'auto',
  }
}

// override sign in success redirect with signInSuccessWithAuthResult closure
const Modal = ({children, onRequestClose, ...rest}) => {

  const closeButtonStyle = {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 30,
    height: 30,
    color: '#384C74',
    cursor: 'pointer',
  }

  // get rid of warning Warning: react-modal: App element is not defined. Please use `Modal.setAppElement(el)` or set `appElement={el}`. This is needed so screen readers don't see main content when modal is opened. It is not recommended, but you can opt-out by setting `ariaHideApp={false}`.
  // https://github.com/reactjs/react-modal/issues/133
  useEffect(() => {
    ReactModal.setAppElement('body')
  })

  return (
    <ReactModal
      {...rest}
      style={customStyles}
    >
      { children }
    <XCircle style={closeButtonStyle} onClick={onRequestClose}/>
    </ReactModal>
  )
}

export default Modal
