import {
  Link,
  navigate,
  graphql,
  useStaticQuery,
} from 'gatsby'
import React, {useState} from 'react'
import Navbar from 'react-bootstrap/Navbar'
import NavigationClose from 'src/images/icons/navigation-close.svg'
import Img from "gatsby-image"
import styled from 'styled-components';

/*
notes:
the navbar is a funky mix of landkit and react-bootstrap. i experimented
with using react-bootstrap more consistently but hit some problems.
react-bootstrap relies on bootstrap css for its styling. i do not use bootstrap css directly
since landkit acts as a replacement for it.
the easiest path through was to use react-bootstrap nav bar as much as possible for functionality
while making some changes so landkit stylings could be applied properly.

update:
adding in styled components to override some styles
 */

// queries for desktop and mobile logo images
 export const logoQuery = graphql`
  query {
    desktopImage: file(relativePath: { eq: "diy-logo.png" }) {
      childImageSharp {
        fixed(width: 30, height: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    mobileImage: file(relativePath: { eq: "diy-logo.png" }) {
      childImageSharp {
        fixed(width: 20, height: 20) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const StyledNavbar = styled(Navbar)`
  padding: 1rem 0;

  // shooting for around 64px height, same as ios nav bar
  @media only screen and (max-width: 767px) {
    padding: 13px 0px;
  }
`

const StyledNavbarBrand = styled(Navbar.Brand)`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 767px) {
    font-size: 16px;
  }
`

const StyledLogo = styled(Img)`
  margin-right: 15px;
`

const NavigationItemLink = ({to, name}) => {
  return (
    <li className="nav-item">
      <Link className="nav-link" to={to}>{name}</Link>
    </li>
  )
}

const Header = ({ siteTitle, firebase, currentUser, onLoginPress, onLogout, }) => {

  // responsive logo images:
  const logoData = useStaticQuery(logoQuery)
  const sources = [
    logoData.mobileImage.childImageSharp.fixed,
    {
      ...logoData.desktopImage.childImageSharp.fixed,
      media: `(min-width: 768px)`,
    },
  ]

  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false)
  const toggleNavbar = () => {
    setIsNavbarExpanded((isExpanded) => !isExpanded)
  }
  const logout = () => {
    if (!firebase) {
      return
    }
    firebase.auth().signOut()
    .then(() => {
      if (onLogout) {
        onLogout()
      } else {
        navigate('/')
      }
    }).catch(function(error) {
      console.error(`firebase signout error: ${error}`)
    });
  }
  let loginOrLogoutButton = null
  if (firebase && !currentUser) { // or user is anonymous
    loginOrLogoutButton = <div className="nav-link" onClick={onLoginPress}>Login</div>
  } else if (firebase && currentUser) {
    loginOrLogoutButton = <div className="nav-link" onClick={logout}>Logout</div>
  }

  return (
    <StyledNavbar expanded={isNavbarExpanded} className="navbar navbar-expand-lg navbar-dark bg-dark" bg="dark" expand="lg">
      <div className='container-fluid'>

        <StyledNavbarBrand href="/">
          <StyledLogo
            fixed={sources}
            alt="nextlevelresume.co logo"
          />
          NextLevelResume.co
        </StyledNavbarBrand>
        <Navbar.Toggle onClick={toggleNavbar} className="navbar-toggler" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="collapse navbar-collapse"  id="basic-navbar-nav">
          <button className="navbar-toggler" onClick={toggleNavbar}>
            <NavigationClose fill='#384C74'/>
          </button>
          <ul className="navbar-nav ml-auto" onClick={toggleNavbar}>
            <NavigationItemLink name='Home' to='/'/>
            {
              currentUser &&
              <NavigationItemLink name='My Resumes' to='/resumes'/>
            }
            {
              currentUser &&
              <NavigationItemLink name='Account' to='/account'/>
            }
            {
              currentUser &&
              <NavigationItemLink name='Plans' to='/plans'/>
            }
            <li className="nav-item">
              {loginOrLogoutButton}
            </li>
          </ul>
        </Navbar.Collapse>
      </div>
    </StyledNavbar>
    )
}

export default Header
