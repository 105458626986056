import { useEffect, useState } from 'react';
import getFirebase from 'src/utilities/firebase'; // import our getFirebase function

// instructed by https://invertase.io/blog/firebase-with-gatsby


export default function useFirebase() {
  const [instance, setInstance] = useState(null);
  // component did mount, there is a window, there is a firebase
  useEffect(() => {
    setInstance(getFirebase());
  }, []);
  return instance;
}
